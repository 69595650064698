<template>
    <div class="inviteFriends">
<!--        上部背景图-->
        <div class="topBackground">
            <!--        导航-->
            <div class="nav" >
                <div @click="leftReturn">
                    <img src="../../assets/images/InviteFriends-navImg.png" >
                </div>
<!--                <rxNavBar title="" androidOrIOSFlag="true"></rxNavBar>-->
            </div>
<!--            背景图-->
            <div class="topBackgroundImg">
                <img src="../../assets/images/InviteFriends-backgroundImg.png" alt="">
            </div>
<!--            上部问候语部分-->
            <div class="topBackgroundTitle">
                <p class="titleSpan">{{dictionaryTitle}}</p>
                    <span class="titleText">{{ dictionaryDescription }}</span>
            </div>
<!--            推荐按钮-->
            <div class="inviteBtn">
                <button @click="invite">推荐海米给好友</button>
            </div>
        </div>
<!--        下部用户信息-->
        <div class="userInfo">
            <div class="userInfoTitle">
                <img :src="headPortraitUrl"/>
                <span class="userName">{{userName}}</span>
                <span class="userIntegral"><i>{{integral }} </i> 积分</span>
            </div>
<!--            分割线-->
            <div class="rule"></div>
<!--            邀请好友 数-->
            <div>
                    <span class="inviteCount">已成功邀请   <i>{{gifTypeDetailedList != undefined ? gifTypeDetailedList.length:'0'}}</i>  名好友</span>
            </div>
<!--            循环列表信息-->
            <div >
                <div class="forList" v-for=" item in gifTypeDetailedList">
                    <p>{{item.userName}}</p>
                    <p>+ {{item.integral}} 积分 </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { NavBar} from 'vant';
    import {userIntegralInfo} from "../../getData/getData";
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import {
      checkAndroid,
      checkIOS, globaluserId, responseUtil
    } from "../../libs/rongxunUtil";
    //调用android关闭页面方法*******begin*********
    function backToAndroid(){
        window.himi.closeWindow();
    }
    //调用android关闭页面方法*******end***********

    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }
    /********判断H5页面是否在web容器中 start*********/
    function shareToWX(shareTitle,shareImage) {
        window.himi.shareToWX(shareTitle,shareImage)

    }
    export default {
        components: {
            [NavBar .name]:NavBar,
            rxNavBar,
        },
        data() {
            return {
                //用户昵称
                userName: '',
                //总积分数
                integral:'',
                dictionaryTitle:'',
                // 头像的url
                headPortraitUrl:require('../../assets/images/InviteFriends-backgroundImg.png'),
               //导航栏返回的url
                returnUrl:require('../../assets/images/InviteFriends-backgroundImg.png'),
                //问候语
                dictionaryDescription:'',
              //分享标题
              shareTitle: '',
              //分享图片
              shareImage: '',
                //成功邀请好友 加积分详情列表
                gifTypeDetailedList:[]
            }
        },
        created() {
            this.initData();
        },
        methods:{
          //推荐
          invite() {
            if(openInWebview()){
              //APP传值*******begin*********
              if(checkAndroid()){
                  shareToWX(this.shareTitle,this.shareImage);
              }else if(checkIOS()){
                  let shareContent = {}
                  shareContent.shareTitle = this.shareTitle;
                  shareContent.shareImage = this.shareImage;
                window.webkit.messageHandlers.shareToWX.postMessage({body: shareContent})
              }
              //APP传值*******end*********
            }
          },
            //用户邀请初始化
            initData(){
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                userIntegralInfo(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data);
                        // debugger
                      let recommendGift = response.data.data.recommendGift
                      for(let i in recommendGift){
                        switch (recommendGift[i].dictionaryName) {
                          case 'title':
                            that.dictionaryTitle = recommendGift[i].dictionaryValue;
                            break;
                          case 'describe':
                            that.dictionaryDescription = recommendGift[i].dictionaryValue;
                            break;
                          case 'shareTitle':
                            that.shareTitle = recommendGift[i].dictionaryValue;
                            break;
                          case 'shareImage':
                            that.shareImage = recommendGift[i].dictionaryValue;
                            break;
                        }
                      }
                        that.userName = response.data.data.userName
                        that.integral =response.data.data.integral
                        that.gifTypeDetailedList = response.data.data.gifTypeDetailedList
                        that.headPortraitUrl = response.data.data.path || require('../../assets/images/InviteFriends-backgroundImg.png')
                    })
                })
            },
            //返回上一层
            leftReturn(){
                //APP传值*******begin*********
                if(openInWebview()){
                    //APP传值*******begin*********
                    if(checkAndroid()){
                        console.log("----checkAndroid()-=true")
                        backToAndroid();
                    }else if(checkIOS()){
                        console.log("----checkAndroid()-=true")
                        window.webkit.messageHandlers.closeWindow.postMessage({});
                        // console.log("对ios传参，调用IOS关闭页面方法,zheshi");
                    }
                    //APP传值*******end*********
                }else{
                    this.$router.go(-1);
                }
            },
        }
    }
</script>

<style lang="less" scoped >

    /*总DIV */
    .inviteFriends{
        width: 100%;
        height: 100%;
        background-image:linear-gradient(to bottom ,#FFC274,#FF5D3B);
        background-color:#FF5D3B ;
        padding-bottom: 130px;
    }
    *{
        margin: 0;
        padding: 0;
    }
/*    导航*/
    .nav{
        position: fixed;
        left: 10px;
        margin: 0 auto;
        width: 94%;
        top: 6px;
    }
    .nav img{
        height: 23px;
    }
/*    顶部title*/
    .topBackground{
    position: relative;
    }
    /*顶部背景图片DIV*/
    .topBackgroundImg{
        width: 100%;
        height: 420px;
        margin: 0 auto;
        border-radius: 8px;
        overflow: hidden;
    }
    /*顶部图片*/
    .topBackgroundImg>img{
        width: 100%;
        height: 100%;
    }
/*    顶部问候语部分*/
    .topBackgroundTitle{
        width: 250px;
        height: 100px;
        position: absolute;
        left: 65px;
        top: 180px;
    }
    /*问候语标题*/
    .titleSpan {
        font-size: 16px;
        font-weight: bold;
    }
    /*问候语详细信息*/
    .titleText{
        font-size: 13px;
        width: 250px;
        height: 75px;
        word-break:normal;
        width:auto;
        display:block;
        white-space:pre-wrap;
        word-wrap : break-word ;
        overflow: hidden ;
        line-height: 25px;
    }
    /*推荐按钮*/
    .inviteBtn button{
        position: absolute;
        top: 363px;
        left: 96px;
        height: 45px;
        width: 180px;
        font-size: 16px;
        background-image: linear-gradient(to bottom, #FD6F7D, #F41738)  ;
        border-radius: 23px;
        text-align: center;
        line-height: 45px;
        color: white;
        border: none;
    }
/*    下部用户信息部分*/
    .userInfo{
        margin : 30px 12px 40px;
        padding-top: 15px;
        padding-bottom: 10px;
        background-color: white;
        border-radius: 8px;
    }
    /*用户头像 昵称 总积分DIV*/
    .userInfoTitle{
        display: flex;
        align-items: center;
        font-size: 13px;
        margin: 0 auto;
        width: 90%;
    }
    .userInfoTitle img{
        flex-grow: 0.00001;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
/*    用户昵称*/
    .userName{
        flex-grow: 0.95;
        margin-left: 10px;
    }
/*    积分*/
    i{
        color: red;
    }
    .userIntegral
    {
        flex-grow: 0.0001;
    }
    .rule{
        width: 99.2%;
        border:0.5px solid #eee;
        margin-top: 15px;
        /*margin-bottom: 10px;*/
    }
    /*成功邀请数量*/
    .inviteCount{
        font-size: 13px;
        font-weight: bold;
        margin-left: 20px;
    }
/*    循环列表*/
    .forList p{
        font-size: 13px;
        color: #999999;
        line-height: 10px;
        background-color: #FEFDF8;
    }
    .forList{
        display: flex;
        margin:15px 20px ;
        justify-content: space-between;
    }

</style>
